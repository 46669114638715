import "core-js/modules/es.array.concat.js";
import http from 'utils/http';
export default {
  /* 活动-列表 */getCampainList: function getCampainList(params) {
    return http({
      url: "/api/platform/campain/campain",
      method: 'get',
      params: params
    });
  },
  /* 活动-列表 */getCampainListAuth: function getCampainListAuth(params, id) {
    return http({
      url: "/api/platform/campain/campain/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-是否展示活动页 */getIsShowIntroduceAuth: function getIsShowIntroduceAuth(params, id, type) {
    return http({
      url: "/api/platform/content/desc/status/".concat(id, "/").concat(type),
      method: 'get',
      params: params
    });
  },
  /* 活动-更新展示活动页 */putShowIntroduceAuth: function putShowIntroduceAuth(params, id, type) {
    return http({
      url: "/api/platform/content/desc/status/".concat(id, "/").concat(type),
      method: 'put',
      params: params
    });
  },
  /* 活动-模块列表 */getCampainSectionList: function getCampainSectionList(params) {
    return http({
      url: "/api/platform/campain/campain-section",
      method: 'get',
      params: params
    });
  },
  /* 活动-模块详情 */getCampainSectionDetail: function getCampainSectionDetail(params, id) {
    return http({
      url: "/api/platform/campain/campain-section/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-模块详情 */getCampainSectionDetailAuth: function getCampainSectionDetailAuth(params, id) {
    return http({
      url: "/api/platform/campain/campain-section/section/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-模块-内容管理 */getCampainContent: function getCampainContent(params) {
    return http({
      url: "/api/platform/campain/campain-content",
      method: 'get',
      params: params
    });
  },
  /* 活动-模块-内容管理 */getCampainContentId: function getCampainContentId(params, id) {
    return http({
      url: "/api/platform/campain/campain-content/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-三级权限 */getCheckPermissions: function getCheckPermissions(params, type, id) {
    return http({
      url: "/api/platform/campain/check/permissions/".concat(type, "/").concat(id),
      method: 'get',
      params: params
    });
  },
  // 获取2024年终h5数据
  get2024Data: function get2024Data(params) {
    return http({
      url: "/api/platform/year/h5/data/2024",
      method: 'get',
      params: params
    });
  },
  //2024h5埋点
  setViewLog: function setViewLog(params) {
    return http({
      url: "/api/platform/year/h5/data/2024/view/log",
      method: 'post',
      data: params
    });
  },
  //发放学术能量
  setFinishedNum: function setFinishedNum(params) {
    return http({
      url: "/api/platform/year/h5/data/2024/finished",
      method: 'post',
      data: params
    });
  }
};