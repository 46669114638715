var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-register",
    on: {
      touchmove: function touchmove($event) {
        $event.preventDefault();
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    on: {
      click: function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("div", {
    staticClass: "popFullInnerContent popFullInnerContent-slot"
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_c("div", {
    staticClass: "flex align-center justify-between",
    staticStyle: {
      "margin-top": "0.3rem",
      "font-size": "13px",
      color: "rgb(0, 97, 127)"
    }
  }, [_vm._v(" 请您向下滑动政策页面至底部，阅读并点击“同意以上所有”，再点击“签署”以确认。感谢您的支持！ ")]), _c("div", {
    staticClass: "flex align-center justify-between",
    staticStyle: {
      "margin-top": "0.2rem",
      "margin-left": "-1rem",
      "margin-right": "-1rem"
    }
  }, [_c("iframe", {
    staticStyle: {
      width: "100%",
      height: "450px",
      border: "0"
    },
    attrs: {
      src: "https://documents.truman.bayer.cn/cn_ph/hcp/zh/cn_consent_statement/index.html"
    }
  })]), _c("div", {
    staticClass: "flex align-center justify-between"
  }, [_c("div", {
    staticClass: "flex align-center"
  })])]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.toRegister.apply(null, arguments);
      }
    }
  }, [_vm._v(" 签署 ")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }, [_vm._v(" 取消 ")])])])])])]) : _vm._e()]), !_vm.loadFinal ? _c("div", {
    staticClass: "meetingEmpty flex align-center justify-center"
  }, [_vm._v(" 加载中... ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };