import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: '/activity/detailenter',
  name: 'detailBefore',
  component: function component() {
    return import('views/activity/detailBefore');
  },
  meta: {
    title: '详情',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动详情',
      desc: '活动详情'
    },
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/activeShowOncePoster',
  name: 'activeShowOncePoster',
  component: function component() {
    return import('views/activity/activeShowOncePoster');
  },
  meta: {
    title: '活动',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动',
      desc: '活动'
    },
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/activeShowOnceForm',
  name: 'activeShowOnceForm',
  component: function component() {
    return import('views/activity/activeShowOnceForm');
  },
  meta: {
    title: '活动',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动',
      desc: '活动'
    },
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/detail',
  name: 'detail',
  component: function component() {
    return import('views/activity/detail');
  },
  meta: {
    title: '所有内容',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动所有内容',
      desc: '活动所有内容'
    },
    reachBottom: true,
    checkUser: true,
    hideApproval: true
  }
}, {
  path: '/activity/secondList',
  name: 'secondList',
  component: function component() {
    return import('views/activity/secondList');
  },
  meta: {
    title: '模块列表',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜热门活动',
      desc: ''
    },
    checkUser: true,
    reachBottom: true
  }
}, {
  path: '/activity/richlist',
  name: 'activityRichList',
  component: function component() {
    return import('views/activity/richText/list');
  },
  meta: {
    title: '所有内容',
    keepAlive: true,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动所有内容',
      desc: '活动所有内容'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: true
  }
}, {
  path: '/activity/richdetail',
  name: 'activityRichDetail',
  component: function component() {
    return import('views/activity/richText/detail');
  },
  meta: {
    title: '所有内容',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜活动所有内容',
      desc: '活动所有内容'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: true
  }
},
// 年度账单h5 task retrospect
// {
//   path: '/annual-2023/task', name: 'task',
//   component: () => import('views/annual-2023/task'),
//   meta: {
//     title: '开启全新「影像」人生', keepAlive: false, wechatAuth: true, share: { title: '开启全新「影像」人生', desc: '开启全新「影像」人生' }, reachBottom: true, hideApproval: true, checkUser: false
//   }
// },
// {
//   path: '/annual/retrospect', name: 'retrospect',
//   component: () => import('views/annual-2023/retrospect'),
//   meta: {
//     title: '开启全新「影像」人生', keepAlive: false, wechatAuth: true, share: { title: '开启全新「影像」人生', desc: '开启全新「影像」人生' }, reachBottom: true, hideApproval: true, checkUser: true
//   }
// },
// 年度账单h5 task retrospect 2024
{
  path: '/annual/task',
  name: 'task',
  component: function component() {
    return import('views/annual/task');
  },
  meta: {
    title: '开启全新「影像」人生',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '开启全新「影像」人生',
      desc: '开启全新「影像」人生'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: false
  }
}, {
  path: '/annual/retrospect',
  name: 'retrospect',
  component: function component() {
    return import('views/annual/retrospect');
  },
  meta: {
    title: '开启全新「影像」人生',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '开启全新「影像」人生',
      desc: '开启全新「影像」人生'
    },
    reachBottom: true,
    hideApproval: true,
    checkUser: true
  }
}];